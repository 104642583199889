import React from 'react'

import { createBrowserRouter } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { LetterDuplicatedErrorPage, LetterExpiredErrorPage } from './pages/Error'
import { LetterAgreementPage, LetterSubmittedPage } from './pages/Letter'

import RootProvider from './providers'
import IntroducePage from './pages/Introduce'
import SubmissionPage from './pages/Submission'
import DocumentsPage from './pages/Documents'

const router = createBrowserRouter([
  {
    path: '',
    element: (
      <RootProvider>
        <Outlet />
      </RootProvider>
    ),
    children: [
      {
        path: '',
        element: <LetterExpiredErrorPage />,
      },
      {
        path: '/letters/:letterId',
        element: <LetterAgreementPage />,
      },
      {
        path: '/submission/:letterId',
        element: <SubmissionPage />,
      },
      {
        path: '/expired',
        element: <LetterExpiredErrorPage />,
      },
      {
        path: '/duplicated',
        element: <LetterDuplicatedErrorPage />,
      },
      {
        path: '/submitted',
        element: <LetterSubmittedPage />,
      },
    ],
  },
  {
    path: 'introduce',
    element: <IntroducePage />,
  },
  {
    path: 'documents',
    element: <DocumentsPage />,
  }
])

export default router
