import { SubmissionScreen } from '@rocket-mono/screen-workspace'
import React from 'react'
import { useParams } from 'react-router-dom'

const SubmissionPage: React.FC = () => {
  const { letterId } = useParams()

  return <SubmissionScreen letterId={letterId || ''} />
}

export default SubmissionPage
