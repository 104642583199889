import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

const pad = (num: number, size: number) => {
  let s = num + ''
  while (s.length < size) s = '0' + s
  return s
}

const ANONYMOUS_TOKEN =
  'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxNzI4Mjc5NDUzNDA3Iiwicm9sZXMiOlt7Im5vIjpudWxsLCJuYW1lIjoiUk9MRV9CSVpfQU5PTllNT1VTIn1dLCJsYW5nIjoiZW4iLCJpYXQiOjE3MjgyNzk0NTMsImV4cCI6MTczMDg3MTQ1M30.luHgd4vlBH7ND0ofNcFUOMl52aR3e4-2QcIv83ypKPQ'

const DocumentsPage: React.FC = () => {
  const [imageUrl, setImageUrl] = React.useState<string[]>()

  const fetchImages = React.useCallback(async () => {
    const paths: string[] = new Array(17)
      .fill(null)
      .map((_, index) => `beta-signpad-temp/notice_images/Page_${pad(index + 1, 2)}.png`)
    Promise.all(
      paths.map(async (path) =>
        fetch(`https://file.api.rocket.is/api/files/presigned-url?uploadedPath=${path}`, {
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': ANONYMOUS_TOKEN,
          },
        }).then((response) => response.text()),
      ),
    ).then((data) => setImageUrl(data))
  }, [])

  React.useEffect(() => {
    fetchImages()
  }, [])

  return (
    <View style={styles.container}>
      <Text style={styles.title}>안내영상</Text>
      <iframe
        width="100%"
        height="640"
        src="https://www.youtube.com/embed/PDNWadJnqwI?si=UrM4fGGNIv8_Ijrv"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
      ></iframe>
      {imageUrl ? (
        <>
          <View style={{ height: 20 }} />
          <Text style={styles.title}>안내책자</Text>
          {imageUrl.map((url, index) => (
            <img
              src={url}
              key={index}
              style={{ borderWidth: 1, borderColor: '#eee', borderStyle: 'solid', marginBottom: 10 }}
            />
          ))}
        </>
      ) : (
        ''
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  title: {
    fontSize: 16,
  },
})

export default DocumentsPage
