import React from 'react'

import { AstroProvider, KakaotalkInAppProvider, SigningCampaignProvider } from '@rocket-mono/providers'
import { I18nextProvider } from 'react-i18next'

import { i18nInstance } from '@rocket-mono/libs'

interface Props {
  children?: JSX.Element | JSX.Element[]
}

const RootProvider: React.FC<Props> = ({ children }) => {
  // FIXME: 실제로는 사용자 브라우저 언어를 기준으로 언어를 설정해야 함
  i18nInstance.changeLanguage('ko')
  return (
    <I18nextProvider i18n={i18nInstance}>
      <AstroProvider
        token={''}
        option={{
          environment: 'development',
          baseUrl: 'https://api.rocket.is', // `https://rocket-v1-rocket-is-api-project.develop.gitops.mycroft.kr`,
          authBaseUrl: 'https://authn.api.rocket.is', // 'https://rocket-v2-rocket-authentication.develop.gitops.mycroft.kr',
          secureCdnUrl: 'https://fmvuuttsbkoe14884802.gcdn.ntruss.com', // `https://rocket-v1-rocket-is-api-app.develop.gitops.mycroft.kr`,
          shortenerBaseUrl: 'https://rwk.is', // 'https://rocket-v2-url-shortener.develop.gitops.mycroft.kr',
          talkUrl: 'https://talk.rocket.is', // `https://rocket-v1-rocket-is-talk-react.develop.gitops.mycroft.kr`,
          // talkUrl: 'http://localhost:3001',
          cardBaseUrl: 'https://card.api.rocket.is', // `https://rocket-v1-rocket-is-api-talk.develop.gitops.mycroft.kr`,
          talkBaseUrl: 'https://talk.api.rocket.is', // `https://rocket-v1-rocket-is-api-talk.develop.gitops.mycroft.kr`,
          appApiLegacyUrl: 'https://app.api.rocket.is', // `https://rocket-v1-rocket-is-api-app.develop.gitops.mycroft.kr`,
          talkApiLegacyUrl: 'https://talk.api.rocket.is', // `https://rocket-v1-rocket-is-api-talk.develop.gitops.mycroft.kr`,
          projectApiLegacyUrl: 'https://api.rocket.is', // `https://rocket-v1-rocket-is-api-project.develop.gitops.mycroft.kr`,
          workLegacyUrl: 'https://work.rocket.is', // 'https://rocket-v1-rocket-is-biz.develop.gitops.mycroft.kr',
          todoBaseUrl: 'https://todo.api.rocket.is', // 'https://rocket-v2-todo.develop.gitops.mycroft.kr',
          fileBaseUrl: 'https://file.api.rocket.is', // 'https://rocket-v2-file.develop.gitops.mycroft.kr',
          assigneeBaseUrl: 'https://assignee.api.rocket.is', // `https://rocket-v2-assignee.develop.gitops.mycroft.kr`,
          userBaseUrl: 'https://app.api.rocket.is', // `https://rocket-v1-rocket-is-api-app.develop.gitops.mycroft.kr`,
          notificationBaseUrl: 'https://noti.api.rocket.is', // 'https://rocket-v2-rocket-notification.develop.gitops.mycroft.kr',
          shopBaseUrl: 'https://shop.tableorder.api.rocket.is', // `https://rocket-work-models-table-order-shop.develop.gitops.gitops.mycroft.kr`,
          shopLayoutBaseUrl: 'https://shop-layout.tableorder.api.rocket.is', // `https://rocket-work-models-table-order-shop-layout.develop.gitops.gitops.mycroft.kr`,
          menuBaseUrl: 'https://menu.tableorder.api.rocket.is', // `https://rocket-work-models-table-order-menu.develop.gitops.gitops.mycroft.kr`,
          menuCategoryBaseUrl: 'https://menu-category.tableorder.api.rocket.is', // `https://rocket-work-models-table-order-menu-category.develop.gitops.gitops.mycroft.kr`,
          menuCatalogBaseUrl: 'https://menu-catalog.tableorder.api.rocket.is', // `https://rocket-work-models-table-order-menu-catalog.develop.gitops.gitops.mycroft.kr`,
          menuLabelBaseUrl: 'https://menu-label.tableorder.api.rocket.is', // `https://rocket-work-models-table-order-menu-label.develop.gitops.gitops.mycroft.kr`,
          menuOptionBaseUrl: 'https://menu-option.tableorder.api.rocket.is', // `https://rocket-work-models-table-order-menu-option.develop.gitops.gitops.mycroft.kr`,
          orderBaseUrl: 'https://order.tableorder.api.rocket.is', // `https://rocket-work-models-table-order-order.develop.gitops.gitops.mycroft.kr`,
          tableBaseUrl: 'https://table.tableorder.api.rocket.is', // `https://rocket-work-models-table-order-table.develop.gitops.gitops.mycroft.kr`,
          basketBaseUrl: 'https://basket.tableorder.api.rocket.is', // `https://rocket-work-models-table-order-basket.develop.gitops.gitops.mycroft.kr`,
          tableorderFileBaseUrl: 'https://file.tableorder.api.rocket.is', // `https://rocket-work-models-table-order-file.develop.gitops.gitops.mycroft.kr`,
          bookmarkBaseUrl: 'https://bookmark.api.rocket.is', // 'https://rocket-v2-rocket-bookmark.develop.gitops.mycroft.kr',
          campaignBaseUrl: 'https://campaign.api.rocket.is', // 'https://rocket-v2-services-campaign.develop.gitops.mycroft.kr',
          formBaseUrl: 'https://form.api.rocket.is', // 'https://rocket-v2-services-form.develop.gitops.mycroft.kr',
        }}
      >
        <SigningCampaignProvider>
          <KakaotalkInAppProvider>
            <>{children}</>
          </KakaotalkInAppProvider>
        </SigningCampaignProvider>
      </AstroProvider>
    </I18nextProvider>
  )
}

export default RootProvider
